import { useEffect, useLayoutEffect, useState, useRef } from "react"
import { useWindowSize } from ".";
import { useThrottledEffect } from '.';


const useCssProperties = (ref, props) => {
    const windowSize = useWindowSize();
    const [cssProps, setCssProps] = useState(props);

    useLayoutEffect(() => {
        const updatedProps = { ...cssProps }
        const computedStyle = getComputedStyle(ref.current);

        Object.keys(props).forEach(property => {
            updatedProps[property] = computedStyle.getPropertyValue(`--${property}`)
        })

        setCssProps(updatedProps)
    },[])

    useThrottledEffect(() => {

        console.log('useThrottledEffect')

        const updatedProps = { ...cssProps }
        const computedStyle = getComputedStyle(ref.current);

        Object.keys(props).forEach(property => {
            updatedProps[property] = computedStyle.getPropertyValue(`--${property}`)
        })

        setCssProps(updatedProps)


    }, 100, [windowSize]);
    

    return { ...cssProps }
};

export default useCssProperties