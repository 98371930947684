import React, { useState, useEffect } from 'react';
import SlideImage from './Image';
import styles from './Slideshow.module.sass'

const Slideshow = ({ data, onClick }) => {
    const [loadedImages, setLoadedImages] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [animation, setAnimation] = useState({
        currentImage: 0,
        currentDirection: 1
    });

    useEffect(() => {
        if (loadedImages < data.images.length) {
            const path = data.images[loadedImages].path;
            const preload = new Image()

            preload.onload = () => {
                console.log('loaded')
                setLoadedImages(loadedImages + 1);
                setIsRunning(loadedImages + 1 === data.images.length)
            };
            preload.src = path;
        }
    }, [loadedImages])

    useEffect(() => {

        if (isRunning) {
            const interval = setInterval(() => {

                let { currentImage, currentDirection } = animation

                if(currentImage+currentDirection>=data.images.length) {
                    currentImage = currentImage -1
                    currentDirection = -1

                } else if(currentImage+currentDirection<0) {
                    currentImage = currentImage +1
                    currentDirection = 1

                } else {
                    currentImage+=currentDirection; 
                }

                setAnimation({currentImage, currentDirection})

            }, data.interval);

            return () => {
                clearInterval(interval);
            };
        }

    }, [isRunning, animation])

    console.log(animation.currentImage)

    return (
        <div 
            className={styles.root}
            style={{ '--x': data.x, '--y': data.y }}
            onClick={onClick}
        >
            <div className="slides">
                {data.images.map((item, index) => (
                    <SlideImage
                        data={item}
                        key={index}
                        isLoaded={index <= loadedImages}
                        isActive={index === animation.currentImage}
                    />
                ))}
                <h2>{data.title}</h2>
            </div>
        </div>
    );
};

export default Slideshow;