import React, { useEffect, useState } from 'react';
import { GetPage } from '../../services'
import { useDispatch, useSelector } from 'react-redux';
import Slideshow from '../Slideshow';
import styles from './Home.module.sass'

const PageHome = () => {
    const [ currentSlide, setCurrentSlide ] = useState({x: 0, y: 0});

    const dispatch = useDispatch()
    const page = useSelector(state => state.page.pages.find(page => page.id === 7))

    console.log(page)

    useEffect(() => {
        console.log('test')
        dispatch(GetPage('pages/7'))
    }, [dispatch])

    if (!page) return 'loading...'

    let translateX = currentSlide.x * -100;
    let translateY = currentSlide.y * -100;

    return (
        <div className={styles.root}>
            <div 
                className="slides"
                style={{transform : `translate(${translateX}%, ${translateY}%)`}}
            >
                {page.repertoires.map((item, index) => (
                    
                        <Slideshow
                            key={index}
                            data={item}
                            onClick={() => setCurrentSlide({x: item.x, y: item.y})}
                        />
                    
                ))}
            </div>
        </div>
    );
};

export default PageHome;