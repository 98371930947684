import { createSlice } from "@reduxjs/toolkit";
import { GetPage, CreatePost } from "../../services";

export const initialState = {
    pages: [],
    loading: false,
    error: null,
};

export const pageSlice = createSlice({
    name: "page",
    initialState: initialState,
    extraReducers: {
        [GetPage.fulfilled]: (state, action) => {
            console.log(action.payload)
            state.pages = [...state.pages, action.payload];
        },
        [GetPage.rejected]: (state, action) => {
            state.pages = [];
        },
        [CreatePost.fulfilled]: (state, action) => {
            state.pages.unshift(action.payload.data);
        },
    },
});

export default pageSlice.reducer;