import React from 'react';
import { usePreload } from 'uitls/hooks';
import cls from 'classnames';

const Image = ({data, isLoaded, isActive}) => {
    //const isLoaded = usePreload(data.path, true);

    return (
        <div className={cls('image', {active: isActive})}>
            {isLoaded && 
                <img src={data.path} />
            }
        </div>
    );
};

export default Image;