import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = 'https://nulapali.myhostpoint.ch/wordpress/wp-json/wp/v2';


export const GetPage = createAsyncThunk(
    'page/getPage',
    async (path) => {
     
      
  
      const response = await fetch(`${BASE_URL}/${path}`)
      const json = await response.json()

      console.log(json)
     
      return json
    }
  )

export const CreatePost = createAsyncThunk(
    "post/createPost", async (post) => await axios.post(`${BASE_URL}/post`, post)
);